<template>
    <div class="loginBg">
        <div id="main">
            <div class="container">
                <div class="title wow animated fadeInDown">{{$t('login.register')}}</div>
                
                <div class="form_box wow animated fadeIn">
                    <el-form ref="formData" :model="formData" :rules="rules" label-position="top">
                        <el-form-item :label="$t('common.userName')" prop="account">
                            <el-input v-model="formData.account" :placeholder="$t('tip.accountPlaceHolder')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('common.email')" prop="email">
                           <el-input v-model="formData.email" :placeholder="$t('tip.emailEmpty')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('common.verification')" prop="sms_code">
                            <div class="inp_code">
                                <el-input v-model="formData.sms_code" :placeholder="$t('tip.emailEmpty')"></el-input>
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s
                                </div>
                                <div v-else class="g_btn btn" @click="handleSendCode">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item :label="$t('common.pwd')" prop="password">
                           <el-input v-model="formData.password" type="password" :placeholder="$t('tip.commonVer')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('login.confirm')" prop="password2">
                           <el-input v-model="formData.password2" type="password" :placeholder="$t('tip.commonVer')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('tip.invite')" prop="code">
                           <el-input v-model="formData.code" :placeholder="$t('tip.inviteVer')"></el-input>
                        </el-form-item>

                        <el-button @click="register" class="btn login" :loading="loading">{{$t('common.register')}}
                        </el-button>
                        <router-link to="/login" class="btn btn-o">
                            <span class="gradient">{{$t('login.toLogin')}}</span>
                        </router-link>
                        <div class="fd">
                            <label for="readme">
                                <input v-model="checked" type="checkbox" name="readme" id="readme"
                                    class="checkbox">{{$t('login.agree')}}
                            </label>
                            <router-link class="gradient" to="/agreement">《{{$t('common.agreement')}}》</router-link>
                        </div>
                    </el-form>
                </div>
                <div class="login_footer wow animated fadeInUp">
                    <p><span>System version {{version}} </span> @ 2022 LLKKZ.NET ALL RIGHTS RESERVED</p>
                </div>
            </div>
            <div class="icon">
                <img src="@/assets/images/official/login/icon.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
    let timer;
    import { WOW } from 'wowjs'
    import { mapGetters } from 'vuex';
    // api
    import { sendEmail } from '@/api/user'
    export default {
        data() {
            var validateAccount = (rule, value, callback)=>{
                if (!value) {
                    return callback(new Error(this.$t('tip.usernameEmpty')));
                }
                // 检查用户名是否符合要求：6位以上，包含字母和数字
                var accountRegex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/;
                if (!accountRegex.test(value)) {
                    return callback(new Error(this.$t('tip.accountVer')));
                }
                // 验证通过，调用 callback()
                callback();
            };
            var validateEmail = (rule, value, callback)=>{
                if (!value) {
                    return callback(new Error(this.$t('tip.emailEmpty')));
                }
                // 检查邮箱是否符合要求
                var emailReg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                if (!emailReg.test(value)) {
                    return callback(new Error(this.$t('tip.emailVer')));
                }
                // 验证通过，调用 callback()
                callback();
            };
            var validatePassword = (rule, value, callback)=>{
                if (!value) {
                    return callback(new Error(this.$t('tip.pwdEmpty1')));
                }

                // 检查密码是否符合要求：8位以上，包含小写字母、大写字母、数字和符号
                var passwordRegex = /^(?=(.*\d){1})(?=(.*[a-z]){1})(?=(.*[A-Z]){1})(?=(.*[!@#$%^&*()_+|~\-=\`{}\[\]:;"'<>?,./]){1}).{8,}$/;
                // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()-_+=\[\]{};:'"\\|,<.>/?]).{8,}$/;
                
                if (!passwordRegex.test(value)) {
                    return callback(new Error(this.$t('tip.pwdVer')));
                }

                // 验证通过，调用 callback()
                callback();
            };
            var validatePassword2 = (rule, value, callback)=>{
                if (!value) {
                    return callback(new Error(this.$t('tip.pwdEmpty1')));
                }

                // 检查密码是否符合要求：8位以上，包含小写字母、大写字母、数字和符号
                var passwordRegex = /^(?=(.*\d){1})(?=(.*[a-z]){1})(?=(.*[A-Z]){1})(?=(.*[!@#$%^&*()_+|~\-=\`{}\[\]:;"'<>?,./]){1}).{8,}$/;
                // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()-_+=\[\]{};:'"\\|,<.>/?]).{8,}$/;
                
                if (!passwordRegex.test(value)) {
                    return callback(new Error(this.$t('tip.pwdVer')));
                }
                if (value!=this.formData.password) {
                    return callback(new Error(this.$t('tip.pwd2')));
                }

                // 验证通过，调用 callback()
                callback();
            };
            return {
                loading: false,

                formData: {},
                rules:{
                    account: [
                        { required: true, message: this.$t('tip.usernameEmpty'), trigger: 'blur' },
                        { min: 6, message: this.$t('tip.accountVer'), trigger: 'blur' },
                        { 
                            validator: validateAccount, 
                            trigger: 'blur' 
                        }
                    ],
                    email: [
                        { required: true, message: this.$t('tip.emailEmpty'), trigger: 'blur' },
                        { 
                            validator: validateEmail, 
                            trigger: 'blur' 
                        }
                    ],
                    sms_code: [
                        { required: true, message: this.$t('tip.codeEmpty'), trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: this.$t('tip.pwdEmpty1'), trigger: 'blur' },
                        { min: 8, message: this.$t('tip.pwdVer'), trigger: 'blur' },
                        { 
                            validator: validatePassword, 
                            trigger: 'blur' 
                        }
                    ],
                    password2: [
                        { required: true, message: this.$t('tip.pwdEmpty1'), trigger: 'blur' },
                        { 
                            validator: validatePassword2, 
                            trigger: 'blur' 
                        }
                    ],
                    // code: [
                    //     { required: true, message: this.$t('tip.inviteVer'), trigger: 'blur' },
                    // ],
                },
                checked: false,

                // 邮箱验证
                codeSending: false,
                timeCount: 60,
                firstSend: true,
            }
        },
        computed: {
            ...mapGetters(['version', 'isTwitter'])
        },
        mounted() {
            this.$nextTick(() => {
                this.wow();
            })
        },
        methods: {
            wow() {
                if (!(/msie [6|7|8|9]/i.test(navigator.userAgent))) {// 在非 IE 6-9 浏览器中执行逻辑
                    var wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 50,
                        mobile: true,
                        live: true
                    });
                    this.$nextTick(() => {
                        wow.init();
                    });
                };
            },
            // 发送验证码
            handleSendCode() {
                if (!this.codeSending) {
                    var emailReg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
                    // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    if (!this.formData.account) {
                        this.$message({
                            message: this.$t('tip.usernameEmpty') + '！',
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    } else if (!this.formData.email) {
                        this.$message({
                            message: this.$t('tip.emailEmpty') + '！',
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    } else if (!emailReg.test(this.formData.email)) {
                        this.$message({
                            message: this.$t('tip.emailVer') + '！',
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    } else {
                        // 发送验证码
                        this.timeCount = 60;
                        this.codeSending = true;
                        this.firstSend = false;
                        clearInterval(timer);
                        timer = setInterval(() => {
                            if (this.timeCount >= 1) {
                                this.timeCount -= 1;
                            } else {
                                clearInterval(timer);
                                this.codeSending = false;
                            }
                        }, 1000);

                        sendEmail({
                            account: this.formData.account,
                            email: this.formData.email,
                            type: 3,
                        })
                    }
                }
            },
            register() {
                if (this.loading) {
                    return
                }
                // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                // var pwdReg=/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/
                // const reg = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/;
                // const pwdReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()-_+=\[\]{};:'"\\|,<.>/?]).{8,}$/;
                // var emailReg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                this.$refs.formData.validate((valid) => {
                    if (valid) {
                        if (!this.checked) {
                            this.$message({
                                message: this.$t('tip.check') + '！',
                                type: 'error',
                                center: true,
                                customClass: 'shotMsg',
                            });
                        } else {
                            var register_source = '0';
                            if (this.isTwitter == 1) {
                                register_source = 'twitter'
                            } else if (this.isTwitter == 2) {
                                register_source = 'qrcode'
                            }
                            this.loading = true
                            this.$store.dispatch('user/register', {
                                account:this.formData.account,
                                password: this.formData.password,
                                code: this.formData.code,
                                register_source,
                                email: this.formData.email,
                                sms_code: this.formData.sms_code
                            }).then(res => {
                                this.loading = false;
                                this.$message({
                                    message: this.$t('tip.registerSuc'),
                                    center: true,
                                    type: 'success',
                                    customClass: 'shotMsg',
                                });
                                // 注册进入需要新手引导
                                this.$store.dispatch('user/setNovice', 1)
                                this.$router.push({
                                    name: 'Account',
                                    // params:{isRegister:'1'}
                                })
                            }).catch(() => {
                                this.loading = false;
                            })
                        }
                    } else {
                        return false;
                    }
                });
            },
        },
        created() {
            if (this.$route.query.code) {
                this.code = this.$route.query.code;
            }
        },
        beforeDestroy() {
            if (timer) {
                clearInterval(timer)
                timer = ''
            }
        }
    }
</script>
<style scoped="scoped" lang="stylus">
    @import '~@/views/login/login.styl';
    @import '~@/assets/style/frames.styl';
</style>